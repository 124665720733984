.access-denied {
    padding: 40px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    &__card {
        text-align: center;
        padding: 60px 30px 20px;
        background-color: var(--secondary-background-color);
        border-radius: 10px;

        .icon {
            border: 4px solid;
            border-radius: 50%;
            width: 55px;
            height: 55px;
            padding: 10px;
            margin-bottom: 20px;
        }

        .icon {
            color: #e74c3ca1;
        }

        h1 {
            margin-bottom: 10px;
        }

        a {
            padding: 10px 30px;
            display: inline-block;
            border-radius: 5px;
            margin-top: 20px;
        }
    }
}
