.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip {
    &::before,
    &::after {
        position: absolute;
        z-index: 100;
    }

    &::before {
        content: attr(data-tooltip);
        display: block;
        background-color: var(--tooltip-background-color);
        color: white;
        padding: 8px 16px;
        font-size: 0.75rem;
        pointer-events: none;
        border-radius: 3px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 0;
    }

    &.left::before {
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
    &.right::before {
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
    &.top::before {
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    &.bottom::before {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &::after {
        content: "";
        border-width: 7px;
        border-style: solid;
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 0;
    }

    &.left::after {
        margin-right: -14px;
        border-color: transparent transparent transparent
            var(--tooltip-background-color);
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
    &.right::after {
        margin-left: -14px;
        border-color: transparent var(--tooltip-background-color) transparent
            transparent;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    }
    &.top::after {
        margin-bottom: -14px;
        border-color: var(--tooltip-background-color) transparent transparent
            transparent;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    &.bottom::after {
        margin-top: -14px;
        border-color: transparent transparent var(--tooltip-background-color)
            transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    &:hover::before,
    &:hover::after {
        opacity: 1;
        visibility: visible;
        z-index: 100;
    }
}

.card-skeleton {
    width: 40%;
    padding: 16px;
    max-width: 300px;
    height: 300px;
    background-color: var(--secondary-background-color);
    border-radius: 10px;
    margin-bottom: 16px;

    @media (max-width: 767px) {
        width: 47%;
    }
    @media (max-width: 454px) {
        width: 80%;
    }
}

.horizontal-card-skeleton {
    height: 100px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--secondary-background-color);
}

.overview-skeleton-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 140px;
    grid-gap: 20px;

    .overview-skeleton-card {
        background-color: var(--secondary-background-color);
        border-radius: 10px;
    }
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}
.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite alternate;
}
