:root {
    --primary-background-color: rgb(17, 17, 17);
    --secondary-background-color: rgb(30, 30, 30);
    --tooltip-background-color: rgba(77, 77, 77, 0.87);
    --primary-text-color: white;
    --light-green-text-color: #70d8bd;
    --light-blue-text-color: #6870fa;
    --dark-hover: rgba(255, 255, 255, 0.1);
    --light-hover: rgba(0, 0, 0, 0.1);
    --primary-button-color: #fff;
    --hover-button-color: #ffffff14;
    --box-shadow-color: rgba(255, 255, 255, 0.1);
    --search-icon-background-color: #f0f0f0;
    --search-icon-color: #333;
    --search-background-color: white;
    --primary-background-color-hover: rgba(255, 255, 255, 0.1);
    --signout-shadow-color: rgba(255, 255, 255, 0.1);
    --link-color: #627eff;
    --scroll-bar-thumb-color: #4d4d4d;
    --loading-boxes-color: #333;
    .dashboard {
        --primary-background-color: #141b2d;
        --secondary-background-color: #1f2a40;
        --primary-text-color: #e0e0e0;
        --secondary-text-color: #a3a3a3;
        --list-hover-color: #1016245e;
        --table-header-backgroundColor: #3e4396;
    }
}

.light {
    --primary-background-color: white;
    --secondary-background-color: rgb(220, 220, 220);
    --tooltip-background-color: rgba(30, 30, 30, 0.87);
    --primary-text-color: black;
    --light-green-text-color: #70d8bd;
    --light-blue-text-color: #6870fa;
    --dark-hover: rgba(0, 0, 0, 0.1);
    --light-hover: rgba(0, 0, 0, 0.1);
    --primary-button-color: #0000008a;
    --hover-button-color: #0000000a;
    --box-shadow-color: rgba(0, 0, 0, 0.2);
    --search-icon-background-color: #e0e0e0;
    --search-icon-color: #333;
    --search-background-color: #eaeaea;
    --primary-background-color-hover: rgba(0, 0, 0, 0.1);
    --signout-shadow-color: rgba(0, 0, 0, 0.1);
    --link-color: #2d53ff;
    --scroll-bar-thumb-color: #b3b3b3;
    --loading-boxes-color: #999;
    .dashboard {
        --primary-background-color: #fcfcfc;
        --secondary-background-color: #f2f0f0;
        --primary-text-color: #141414;
        --secondary-text-color: #3d3d3d;
        --list-hover-color: #00000014;
        --table-header-backgroundColor: #a4a9fc;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    background-color: var(--primary-background-color);
    color: var(--primary-text-color);
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    font-family: "Inter", sans-serif;
}

// nothing {
//     background-color: #cfcfcf;
//     background-color: #a0a0a0;
//     background-color: #707070;
//     background-color: #414141;
//     background-color: #111111;
//     background-color: #0e0e0e;
//     background-color: #0a0a0a;
//     background-color: #070707;
//     background-color: #030303;
// }

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

ul {
    list-style: none;
}

.container {
    width: 95%;
    margin: auto;
}

.status__holder {
    width: 95%;
    height: 80vh;
    position: relative;
    margin: auto;
}

a {
    color: inherit;
    text-decoration: none;
}

.is-easy {
    background-color: #48c78e;

    &.hover {
        transition: background-color 0.1s ease-in-out;

        &:hover {
            background-color: #3d9e6e;
        }
    }
}

.is-medium {
    background-color: #f1c40f;

    &.hover {
        transition: background-color 0.1s ease-in-out;

        &:hover {
            background-color: #d4ad0f;
        }
    }
}

.is-hard {
    background-color: #e74c3c;

    &.hover {
        &:hover {
            background-color: #c0392b;
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
    background-color: var(--primary-background-color);
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll-bar-thumb-color);
}
