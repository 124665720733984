// Define sizes using a map
$sizes: (
    large: 20px,
    medium: 10px,
    small: 5px,
);

// Define mixin to set size based on class
@mixin setSize($size) {
    width: $size;
    height: $size;
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.fill {
        background-color: inherit;
    }

    &.on-top {
        z-index: 1000;
    }

    &.absolute {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.min-height {
        min-height: 80vh;
    }

    @each $class, $size in $sizes {
        &.#{$class} {
            .loading__animation {
                div {
                    @include setSize($size);
                }
            }
        }
    }

    &__animation {
        display: flex;
        gap: 4px;
        margin: 0 auto;

        div {
            background-color: var(--loading-boxes-color);
            border-radius: 100%;
            display: inline-block;
            animation: bounce 1.4s infinite ease-in-out both;
        }

        div:nth-child(1) {
            animation-delay: 0.16s;
        }
        div:nth-child(2) {
            animation-delay: 0.32s;
        }
        div:nth-child(3) {
            animation-delay: 0.48s;
        }
        div:nth-child(4) {
            animation-delay: 0.64s;
        }

        @keyframes bounce {
            0%,
            100% {
                transform: scale(0);
            }
            50% {
                transform: scale(1);
            }
        }
    }
}
